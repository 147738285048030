.o-container {
  width: 100%;
  margin: 0 auto;
  @media (min-width: 375px){
    max-width: 320px;
  }
  @media (min-width: 640px){
    max-width: 480px;
  }
  @media (min-width: 768px){
    max-width: 576px;
  }
  @media (min-width: 1024px){
    max-width: 620px;
  }
}