@import 'styles/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /// global styles
  * {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-poppins;
  }
  
  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0);
    background-color: transparent;
  }

  ::-webkit-scrollbar
  {
    width: 4px;
    height: 4px;
    background: rgba(0,0,0,0);
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #A9ACB1;
  }

  body {
    @apply bg-white font-nunito font-normal text-black;
  }

  img {
    -webkit-user-drag: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::selection {
    background: #ff000030 !important;
  }

  textarea:focus, input:focus {
    box-shadow: none !important;
  }

  textarea, input {
    outline: none !important;
  }
  
}

@layer components {
  input {
    @apply rounded-[15px] shadow-3xl text-sm font-normal px-5;
  }
  button {
    @apply rounded-[25px] 2xl:rounded-full;
  }
  
  // Profile / Modal / Card Shadow
  .card-dark-shadow {
    box-shadow: -5px 5px 10px rgba(46, 46, 46, 0.2), 5px -5px 10px rgba(46, 46, 46, 0.2), -5px -5px 10px rgba(56, 56, 56, 0.9), 5px 5px 13px rgba(46, 46, 46, 0.9), inset 1px 1px 2px rgba(56, 56, 56, 0.3), inset -1px -1px 2px rgba(46, 46, 46, 0.5);
  } 

  // text blurry effect for loading states
  .blurry-text-effect {
    color: transparent;
    text-shadow: 0 0 12px black;
  }

  .blurry-text-effect-light {
    color: transparent;
    text-shadow: 0 0 12px white;
  }

  .no-scroll::-webkit-scrollbar {
    width: 0px
  }

  .pre-tag-wrap {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
}

.truncated-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}